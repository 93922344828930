import {createApp} from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import VueMeta from 'vue-meta'
import VueSilentbox from 'vue-silentbox'
import BackToTop from 'vue-backtotop'
import VueObserveVisibility from 'vue-observe-visibility'
import { SanityBlocks } from 'sanity-blocks-vue-component';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/material-design-iconic-font.min.css'
import '../src/assets/css/ionicons.min.css'
import '../src/assets/css/flaticon.min.css'
import 'swiper/css';
import '../src/assets/css/animate.min.css'
import '../src/assets/scss/style.scss'
import '../src/assets/css/bootstrap-override.css'

const app = createApp(App);
app.config.productionTip = false;
app.component('sanity-blocks', SanityBlocks);
app.use(VueObserveVisibility)
app.use(VueSilentbox)
app.use(BootstrapVue)
app.use(BackToTop)
app.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})
app.use(router)
app.mount('#app')
